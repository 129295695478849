"use client";
import React, { createContext, useContext } from "react";
import HasNoTeamAccountUser from "~/components/LandingPages/HasNoTeamAccountUser";
import { api } from "~/server/trpc/react";
import { RouterOutputs } from "~/server/trpc/react";

type User = RouterOutputs["teamAccountUser"]["getTeamAccountUser"];

interface TeamAccountContextType {
  teamAccountUser: User | null | undefined;
  updateTeamAccountUser: (teamAccountUser: User) => void | null;
  isLoading: boolean;
}

const TeamAccountUserContext = createContext<TeamAccountContextType | null>(
  null
);

export function useTeamAccountUser() {
  return useContext(TeamAccountUserContext) as TeamAccountContextType;
}

export function TeamAccountUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // query for existing user.
  const {
    data: initialTeamAccountUser,
    isLoading: loadingQuery,
    isSuccess: successQuery,
    refetch,
  } = api.teamAccountUser.getTeamAccountUser.useQuery(void 0, {
    refetchOnWindowFocus: true,
  });
  const { mutate: updateTeamAccountUser } =
    api.teamAccountUser.updateTeamAccountUser.useMutation({
      onSuccess: () => {
        refetch().catch((err) =>
          console.error(err, "TeamAccountUserProvider.tsx")
        );
      },
    });

  const handleUpdateTeamAccountUser = (teamAccountUser: User) => {
    if (!teamAccountUser) return;
    updateTeamAccountUser({
      ...teamAccountUser,
      contactEmail: teamAccountUser.contactEmail ?? undefined,
      firstName: teamAccountUser.firstName ?? undefined,
      lastName: teamAccountUser.lastName ?? undefined,
    });
  };
  if (!initialTeamAccountUser && !loadingQuery && successQuery) {
    return <HasNoTeamAccountUser />;
  }
  if (loadingQuery) {
    return <></>;
  }
  return (
    <TeamAccountUserContext.Provider
      value={{
        teamAccountUser: initialTeamAccountUser,
        updateTeamAccountUser: handleUpdateTeamAccountUser,
        isLoading: loadingQuery,
      }}
    >
      {children}
    </TeamAccountUserContext.Provider>
  );
}
